/*Custom CSS by Code-Brew*/
.animated {
  z-index: 100;
}

/*Forms*/
.add-forms .submitbutton {
  width: 15% !important;
  float: left;
}

.billingaddress {
  float: none !important;
}

/*latitide longitude on forms*/
.getlatlngsandsite {
  position: absolute;
  top: 43.5%;
}
.getlatlng {
  position: absolute;
  top: 49.3%;
}
.specialinstruction {
  float: none !important;
}

.nav > li > a {
  color: #8f9298;
}
/* Assign sandtype to sandsite page*/
.assignsandsite {
  margin-left: 84px;
}

.sandsiteforms {
  width: 28% !important;
}

/* Heading Top Navingation*/
.welcomeheading {
  text-align: center;
  width: 100%;
}

@media (max-width: 400px) {
  .welcomeheading {
    display: none;
  }
}

.multiselect-parent {
  width: 150px;
}
/*add jobs page*/
.checkboxjob {
  width: 100%;
  float: left;
}

/*Completed order*/

.completed-order {
  background-color: #beefcf !important;
}
.completed-order:hover {
  background-color: #9ccc9c !important;
}

/*Google Map Track Triver*/

#map {
  height: 625px;
  width: 100%;
}
.infoWindowContent {
  font-size: 14px !important;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}
/*date time picker*/
.angularjs-datetime-picker {
  z-index: 9999;
}
.angularjs-datetime-picker > .adp-days > .adp-day-of-week,
.angularjs-datetime-picker > .adp-days > .adp-day {
  line-height: 14px !important;
}

/*view order*/
.jobs {
  width: 50%;
  float: left;
}

/*SandCoordinator Submit button*/
.sandcoordinatorSubmit {
  width: auto !important;
}

/*busy sandcoordinator*/

.busy {
  background-color: #f9d6b1 !important;
}
.busy:hover {
  background-color: #f3c08a !important;
}
.row .submitbutton {
  width: auto !important;
}

/* Dashboard Screen */
/* ================ */
.uncknlg-widget {
  padding: 2px 20px 20px 20px;
}

.days-select {
  padding: 20px 230px 20px 20px !important;
}

.dashboard-list .list-group-item {
  line-height: 14px;
}

.list-group-item.scheduled-orders {
  background: $red;
  border-radius: 20px;
}

.list-group-item.scheduled-orders a {
  color: $white;
}

.ibox.sidebard-panel {
  width: 265px;
}
.widget a {
  color: $white;
}
.select-box-div select {
  width: 8% !important;
}

/** Modal edit time*/
.addtiming {
  float: left;
  width: 26%;
  margin-right: 10px;
}
.addtiminggroup {
  margin-bottom: 10px;
  float: left;
}

.minheight {
  clear: both;
  min-height: 140px !important ;
}

.overflowvisible {
  overflow-x: visible;
}

.modalpricing {
  float: left;
  width: 30%;
}

.text-left {
  float: left;
}
.text-left-bootstrap {
  text-align: left !important;
}
.text-right {
  float: right;
  text-align: end;
}
@media print {
  a {
    display: none !important;
  }
}

.bol.invoice-table {
  color: $black;
}
.bol.invoice-table tr td strong {
  text-decoration: underline !important;
  color: $black !important;
}
.bol.invoice-table tr td {
  font-weight: 100;
  color: rgba(2, 1, 1, 0.84);
}
.bol.invoice-table h2,
.bol.invoice-table h4 {
  color: $black;
}
.text-center-bol {
  float: left;
  width: 70%;
  text-align: center;
}
.bol.invoice-table {
  width: 100%;
}

/* track driver view */
.track_driver_view .submitbutton {
  margin: 0 3px 0 3px;
}
#mydiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 440%;
  z-index: 1000;
  background-color: $lightGrey;
  opacity: 0.8;
}
.ajax-loader {
  position: absolute;
  left: 50%;
  top: 10%;
  margin-left: -32px; /* -1 * image width / 2 */
  margin-top: -32px; /* -1 * image height / 2 */
  display: block;
}
